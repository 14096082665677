<template>
    <div>
        <div class="text-center text-muted" v-if="loadingMesas">
            Cargando...
        </div>
        <div v-else>
            <div v-if="mesasFiltered.length>0" class="row">
                <div v-for="(mesa, index) in mesasFiltered" :key="index" class="col-lg-4 mb-3">
                    <router-link :to="{name: 'productos.mesas.show', params: {id: mesa.producto.SLUG, slug: boda.SLUG}}" v-if="mesa.producto">
                        <cardProducto :data="mesa.producto" :id="mesa.CODIGO_BODA_MESA" :mesa="mesa" type="mesa"></cardProducto>
                    </router-link>
                </div>
            </div>
            <div class="text-center text-muted" v-else>
                No hay productos en la mesa
            </div>
        </div>
    </div>
</template>
<script>
import CardProducto from '@/components/productos/CardProducto.vue'
export default {
    components: {
        CardProducto
    },
    data(){
        return {
            filters: {
                mostrar_por: 0,
            },
            loadingMesas: false
        }
    },
    mounted(){
        this.getMesas()
    },
    methods: {
        getMesas(){
            if (!this.boda.CODIGO_BODA) {
                return
            }
            this.loadingMesas = true
            this.$store.dispatch('getMesas', {
                CODIGO_BODA: this.boda.CODIGO_BODA,
                ESTATUS: '0'
            }).then((res) => {
                return res
            }).catch(err => {
                //this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingMesas = false
            })
        }
    },
    computed:{
        mesas() {
            return this.$store.getters.mesas || {}
        },
        mesasFiltered() {
            return this.mesas.data || []
        },
        boda() {
            return this.$store.getters.boda || {}
        },
        codigoBoda(){
            return this.boda.CODIGO_BODA
        }
    },
    watch: {
        codigoBoda(val){
            this.getMesas()
            return val
        }
    }
}
</script>